import React from 'react';
import './bannular.scss';

const LabelBox: React.FC = () => {
  const handleClick = () => {
    // 处理点击事件的逻辑
  };

  return (
    <div className="label_box flex justify-center items-center">
      <div style={{ top: '30px' }} className="br-float-right flex justify-center items-center" onClick={handleClick}>
        <i className="bx bx-current-location" style={{ color: '#1fe091', fontSize: 'x-large' }}></i>
      </div>
      <div style={{ top: '69px' }} className="br-float-right flex justify-center items-center" onClick={handleClick}>
        <i className="bx bxs-joystick" style={{ color: '#1fe091', fontSize: 'x-large' }}></i>
      </div>
      <div style={{ top: '108px' }} className="br-float-right flex justify-center items-center" onClick={handleClick}>
        <i className="bx bxs-vial" style={{ color: '#1fe091', fontSize: 'x-large' }}></i>
      </div>
      <div style={{ top: '147px' }} className="br-float-right flex justify-center items-center" onClick={handleClick}>
        <i className="bx bx-paper-plane" style={{ color: '#1fe091', fontSize: 'x-large' }}></i>
      </div>
    </div>
  );
};

export default LabelBox;
