import { useState } from 'react';
import './index.scss';
const FooterNav = () => {
  const [words, setWords] = useState<string>('');
  const [actives, setActives] = useState<number>(1);

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setWords(e.target.value);
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    // 处理提交逻辑
  };

  return (
    <div className="footer-nav">
      <div className="footer_top flex justify-around items-center">
        <div className="flex justify-center items-center">
          <div className="footer_uls">
            <ul>
              <li className="footer_li_title">产品</li>
              <li><a href="/docs/product" target="_blank" rel="noopener noreferrer"> ITB3-Autotrack</a></li>
              {/* <li><a href="" target="_blank" rel="noopener noreferrer"> IntSoul UAV</a></li> */}
              {/* <li><a href="" target="_blank" rel="noopener noreferrer"> rtImage 图传</a></li> */}
              {/* <li><a href="" target="_blank" rel="noopener noreferrer"> bitCard 开发板</a></li> */}
            </ul>
            <ul>
              <li className="footer_li_title">资源</li>
              <li><a href="/docs/" target="_blank" rel="noopener noreferrer"> 文档</a></li>
              <li><a href="https://github.com/bitrecx" target="_blank" rel="noopener noreferrer"> Github</a>
              </li>
              <li><a href="/docs/privacy" target="_blank" rel="noopener noreferrer"> 隐私政策</a></li>
            </ul>
            <ul>
              <li className="footer_li_title">帮助</li>
              <li><a href="/docs/join" target="_blank" rel="noopener noreferrer"> 加入我们</a></li>
              <li><a href="/docs/question" target="_blank" rel="noopener noreferrer"> 常见问题</a></li>
            </ul>
            <ul>
              <li className="footer_li_title">社区</li>
              <li><a href="https://discord.gg/U6PgegJNVa" target="_blank" rel="noopener noreferrer">
                Discord</a></li>
              <li><a href="https://bilibili.com/bitrecx" target="_blank" rel="noopener noreferrer">bilibili</a></li>
            </ul>
          </div>
        </div>
        <div className="flex justify-start items-center">
          <div className="footer_submit">
            <div className="con-form">
              <h4><b>留下</b> <b>联系方式给我们</b> <br /> <b>您的-邮件.电话</b><br /><b>咨询的问题</b></h4>
              <form onSubmit={handleSubmit}>
                <div style={{ position: 'relative', padding: '5px' }}>
                  <textarea
                    className="words_input"
                    value={words}
                    onChange={handleChange}
                    placeholder="请填入联系方式和需要咨询的问题？"
                    maxLength={600}
                    rows={5}
                  />
                  <button
                    className="rounded-xl py-1 px-1.5 -v-button"
                    style={{
                      bottom: '15px',
                      right: '7px',
                      color: 'var(--burger-menu-text)',
                      position: 'absolute',
                      backgroundColor: 'var(--color-success)',
                      fontSize: 'x-small'
                    }}
                    disabled={actives === 0}
                    onClick={() => setActives(0)}
                    type="submit"
                  >
                    <i className="bx bxs-paper-plane"></i>发送
                  </button>
                  <p
                    className="font_num"
                    style={{
                      bottom: '15px',
                      left: '15px',
                      position: 'absolute',
                      fontSize: 'xx-small',
                      color: 'var(--basic-button-text)',
                      borderRadius: '8px'
                    }}
                  >
                    {words.length}/600
                  </p>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FooterNav;
