import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import FooterNav from '../../views/footer/nav';
import MarkdownLoader from './makdownloader';
import Toc from './toc';
import Sidebar from './sidebar';

import PrevNext from './prevnext';
import './index.scss';

interface TocLink {
    id: string;
    text: string;
    depth: number;
    children?: TocLink[];
}

interface LinkItem {
    _path: string;
    title: string;
}

const Doc: React.FC = () => {
    const [activeFile, setActiveFile] = useState('index.md');
    const [tocLinks, setTocLinks] = useState<TocLink[]>([]);
    const [prevLink, setPrevLink] = useState<LinkItem | undefined>(undefined);
    const [nextLink, setNextLink] = useState<LinkItem | undefined>(undefined);

    const handleTocChange = (links: TocLink[]) => {
        setTocLinks(links);
    };

    return (
        <div className="doc-container">
            <Sidebar onSelect={setActiveFile} />
            <div className="doc-content">
                <MarkdownLoader file={activeFile} onTocChange={handleTocChange} />
                <PrevNext prev={prevLink} next={nextLink} />
            </div>
            <Toc links={tocLinks} />
        </div>
    );
};

export default Doc;