// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.label_box {
  height: 150px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  border-radius: 12px;
}

.br-float-right {
  position: absolute;
  padding: 5px 0px 5px 5px;
  background-color: #ffffff;
  width: 35px;
  right: 0;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  text-align: center;
  box-shadow: -10px -10px 50px 5px rgba(0, 0, 0, var(--vs-shadow-opacity));
  font-size: 12px;
  font-family: hack nf;
  cursor: pointer;
  box-sizing: unset;
}

.br-float-right:hover {
  transition: all 0.1s ease;
  padding: 5px 8px 5px 5px;
  right: 0;
  box-shadow: 0px 0px 30px 5px rgba(0, 0, 0, var(--vs-shadow-opacity));
}`, "",{"version":3,"sources":["webpack://./src/views/infiniteview/bannular.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,WAAA;EAGA,aAAA;EAGA,mBAAA;EAGA,2BAAA;EACA,kBAAA;EACA,mBAAA;AACJ;;AAEE;EACE,kBAAA;EACA,wBAAA;EACA,yBAAA;EACA,WAAA;EACA,QAAA;EACA,4BAAA;EACA,+BAAA;EACA,kBAAA;EACA,wEAAA;EACA,eAAA;EACA,oBAAA;EACA,eAAA;EAAiB,iBAAA;AAErB;;AACE;EAEE,yBAAA;EACA,wBAAA;EACA,QAAA;EACA,oEAAA;AAEJ","sourcesContent":[".label_box {\n    height: 150px;\n    width: 40px;\n    display: -webkit-box;\n    display: -ms-flexbox;\n    display: flex;\n    -webkit-box-align: center;\n    -ms-flex-align: center;\n    align-items: center;\n    -webkit-box-pack: start;\n    -ms-flex-pack: start;\n    justify-content: flex-start;\n    position: relative;\n    border-radius: 12px;\n   }\n\n  .br-float-right {\n    position: absolute;\n    padding: 5px 0px 5px 5px;\n    background-color: #ffffff;\n    width: 35px;\n    right: 0;\n    border-top-left-radius: 10px;\n    border-bottom-left-radius: 10px;\n    text-align: center;\n    box-shadow: -10px -10px 50px 5px rgba(0, 0, 0, var(--vs-shadow-opacity));\n    font-size: 12px;\n    font-family: hack nf;\n    cursor: pointer; box-sizing:unset;\n  }\n\n  .br-float-right:hover {\n    -webkit-transition: all .1s ease;\n    transition: all .1s ease;\n    padding: 5px 8px 5px 5px;\n    right: 0;\n    box-shadow: 0px 0px 30px 5px rgba(0, 0, 0, var(--vs-shadow-opacity));\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
