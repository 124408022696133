import React, { useState, useRef, useEffect } from 'react';
import LabelBox from './bannular';
import './index.scss';

interface InfiniteItem {
    type: string;
    src?: string;
    width?: number;
    height?: number;
    x?: number;
    y?: number;
    color?: string;
    fontSize?: number;
    text?: string;
}

const InfiniteView: React.FC = () => {
    const [isMousedown, setIsMousedown] = useState<boolean>(false);
    const [left, setLeft] = useState<number>(0);
    const [top, setTop] = useState<number>(0);
    const [lleft, setLleft] = useState<number>(0);
    const [ltop, setLtop] = useState<number>(0);
    const [lshow, setLshow] = useState<boolean>(true);
    const [lsl, setLsl] = useState<number>(0);
    const [startLeft, setStartLeft] = useState<number>(0);
    const [startTop, setStartTop] = useState<number>(0);
    const [isShowArrow, setIsShowArrow] = useState<boolean>(true);
    const [infiniteItems, setInfiniteItems] = useState<InfiniteItem[]>([{
        type: "image",
        width: 431.3,
        height: 168.22,
        src: "/img/1683174176436.jpg",
        x: 3174,
        y: 3687,
    }, {
        type: "image",
        width: 351.54,
        height: 167.85,
        src: "/img/1683174250939.jpg",
        x: 3743,
        y: 3760,
    }, {
        type: "image",
        width: 388.2,
        height: 86.79,
        src: "/img/1683174337733.jpg",
        x: 4215,
        y: 3717,
    }, {
        type: "image",
        width: 59,
        height: 59.77,
        src: "/img/1683174350245.svg",
        x: 4646,
        y: 3627,
    }, {
        type: "image",
        width: 256.49,
        height: 109.71,
        src: "/img/1683174365399.jpg",
        x: 3206,
        y: 4112,
    }, {
        type: "image",
        width: 628.51,
        height: 334.06,
        src: "/img/1683174391977.jpg",
        x: 3605,
        y: 4000,
    }, {
        type: "image",
        width: 220.43,
        height: 190.77,
        src: "/img/1683174407877.jpg",
        x: 4367,
        y: 4038,
    }, {
        type: "image",
        width: 120.46,
        height: 528.1,
        src: "/img/1683174421994.jpg",
        x: 4694,
        y: 3828,
    }, {
        type: "text",
        text: ".",
        color: "#000000",
        fontSize: 18,
        x: 3980,
        y: 3930,
    }, {
        type: "arrow",
        x: 4400,
        y: 4400,
    }]);

    const fatherBoxRef = useRef<HTMLDivElement>(null);
    const masterBoxRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (fatherBoxRef.current && masterBoxRef.current) {
            const masterBoxRect = masterBoxRef.current.getBoundingClientRect();
            const screenWidth = window.innerWidth;
            const initialValue = (4000 - screenWidth / 2 + 50);
            setLeft(masterBoxRect.left - initialValue);
            setTop(masterBoxRect.top - 3700);
            setLleft(masterBoxRect.left);
            setLtop(masterBoxRect.top);
        }
        if(window.innerHeight > 1080){
            setIsShowArrow(false);
        }
    }, []);

    const handlerMousedown = (e: React.MouseEvent) => {
        setIsMousedown(true);
        setStartLeft(e.clientX - left);
        setStartTop(e.clientY - top);
        setLshow(false);
        setIsShowArrow(false);
    };

    const handlerMousedover = () => { };

    const handlerMouseup = () => {
        if (isMousedown) {
            setIsMousedown(false);
        }
    };
    const planeRef = useRef<HTMLDivElement>(null);

    // 在 useEffect 中添加对 planeRef 的依赖
    useEffect(() => {
        if (planeRef.current) {
            planeRef.current.style.left = `${left}px`;
            planeRef.current.style.top = `${top}px`;
        }
    }, [left, top]);

    const handlerMousemove: React.MouseEventHandler<HTMLDivElement> = (e) => {
        setLleft(e.clientX - 30);
        setLtop(e.clientY - 80 + window.scrollY);
        if (isMousedown) {
            setLeft(e.clientX - startLeft);
            setTop(e.clientY - startTop);
        }
    };


    return (
        <div className="child" ref={masterBoxRef} style={{ margin: '10px 45px 30px', height: '800px', backgroundColor: '#eef2f2', borderRadius: '23px', boxShadow: '0 0 10px #8f9191' }}>
            <div style={{ width: '100%', height: '100%', overflow: 'hidden', position: 'relative' }} ref={fatherBoxRef} onMouseOver={handlerMousedover}>
                <div id="movingText" className="flex items-center justify-center" style={{ position: 'absolute', fontSize: 'small', zIndex: 9999, borderRadius: '5px', backgroundColor: 'var(--logo-background-color)', color: 'var(--logo-color)', display: lshow ? 'block' : 'none', left: `${lleft}px`, top: `${ltop}px` }}><i className="bx bxs-mouse-alt"></i>左键按下拖动</div>
                <div id="plane" ref={planeRef} className={isMousedown ? 'mousedown' : 'mouseup'} onMouseDown={handlerMousedown} onMouseUp={handlerMouseup as React.MouseEventHandler<HTMLDivElement>} onMouseMove={handlerMousemove} style={{ position: 'absolute' }}>
                    {infiniteItems.map((item, index) => (
                        <div key={index}>
                            {item.type === 'image' && (
                                <img src={item.src} width={item.width} height={item.height} className="infinite_item" style={{ left: `${item.x}px`, top: `${item.y}px` }} onDragStart={(e) => e.preventDefault()} />
                            )}
                            {item.type === 'text' && (
                                <span className="infinite_item" style={{ left: `${item.x}px`, top: `${item.y}px`, color: `${item.color}`, fontSize: `${item.fontSize}px` }}>{item.text}</span>
                            )}
                            {item.type === 'arrow' && (
                                <div style={{ left: `${item.x}px`, top: `${item.y}px` }}>
                                    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="100%" height="100%">
                                        <path d="
                                            M40 100
                                            L40 250
                                            A20,20 0 0,0 60,270
                                            L180 270
                                            A20,20 0 0,1 200,290
                                            L200 360" fill="none" strokeWidth="2" strokeDasharray="15 8" stroke="blue" />
                                    </svg>
                                </div>
                            )}
                        </div>
                    ))}
                </div>
                <div id="control" style={{ top: 0, right: 0, position: 'absolute' }}>
                    <LabelBox />
                </div>
                {isShowArrow && <div id="control" style={{ bottom: '-20px', right: '50%', position: 'absolute' }}>
                    <i className='bx bx-chevron-down bx-fade-up' style={{ color: 'var(--logo-color)', fontSize: '60px', cursor: 'pointer', opacity: .6 }}></i>
                </div>
                }
            </div>
        </div>
    );
};


export default InfiniteView;
