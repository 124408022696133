// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#plane {
  position: relative;
  position: absolute;
  width: 8000px;
  height: 8000px;
}

.mousedown {
  cursor: pointer;
}

.mouseup {
  cursor: auto;
}

.infinite_item {
  position: absolute;
}`, "",{"version":3,"sources":["webpack://./src/views/infiniteview/index.scss"],"names":[],"mappings":"AAAA;EACI,kBAAA;EACA,kBAAA;EACA,aAAA;EACA,cAAA;AACJ;;AAEA;EACI,eAAA;AACJ;;AAEA;EACI,YAAA;AACJ;;AAEA;EACI,kBAAA;AACJ","sourcesContent":["#plane {\n    position: relative;\n    position: absolute;\n    width: 8000px;\n    height: 8000px;\n}\n\n.mousedown {\n    cursor: pointer\n}\n\n.mouseup {\n    cursor: auto\n}\n\n.infinite_item {\n    position: absolute;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
