import React from 'react';
import { Link } from 'react-router-dom';

interface LinkItem {
    _path: string;
    title: string;
}

interface PrevNextProps {
    prev?: LinkItem;
    next?: LinkItem;
}

const PrevNext: React.FC<PrevNextProps> = ({ prev, next }) => {
    return (
        <ul className="prev-next-cont">
            <li className="link-item prev">
                {prev && (
                    <Link to={prev._path} className="flex items-center justify-between">
                        <i className="bx bx-chevron-left"></i>
                        <span>{prev.title}</span>
                    </Link>
                )}
            </li>
            <li className="link-item next">
                {next && (
                    <Link to={next._path} className="flex items-center justify-between">
                        <span>{next.title}</span>
                        <i className="bx bx-chevron-right"></i>
                    </Link>
                )}
            </li>
        </ul>
    );
};

export default PrevNext;
