import React from 'react';

interface TocLink {
    id: string;
    text: string;
    depth: number;
    children?: TocLink[];
}

interface TocProps {
    links: TocLink[];
}

const flattenLinks = (links: TocLink[]): TocLink[] => {
    return links.flatMap(link => {
        const flatChildren = link.children ? flattenLinks(link.children) : [];
        return [link, ...flatChildren];
    });
};

const Toc: React.FC<TocProps> = ({ links }) => {
    const flattenedLinks = flattenLinks(links);

    return (
        <nav className="toc">
            <ul className="toc-links">
                {flattenedLinks.map(link => (
                    <li key={link.id} className={`toc-link _${link.depth}`}>
                        <a href={`#${link.id}`}>
                            {link.text}
                        </a>
                    </li>
                ))}
            </ul>
        </nav>
    );
};

export default Toc;
