const Footer = () => {
    return (
      <div className="footer_bottom" style={{ padding: '60px 50px 20px' }}>
        <div className="flex items-center justify-between">
          <div className="flex items-center justify-between">
            <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
              <img src="/img/logo_ico.png" alt="" style={{ height: '40px', transform: 'translateX(-3000px)', filter: 'drop-shadow(var(--logo-color) 3000px 0)' }} />
              <span style={{ fontFamily: 'BreakBold', fontSize: '26px', marginTop: '8px' }}>bitrec.ai</span>
            </div>
          </div>
          <div className="flex items-center justify-center footer_infos">
            <a style={{ fontFamily: 'BreakBold', fontSize: 'small' }} href="https://beian.miit.gov.cn">备案号：黔ICP备2023006024号-4</a> 
            <span style={{ fontFamily: 'BreakBold', fontSize: 'small' }}>©比特变幻科技</span>
            <span style={{ fontFamily: 'BreakBold', fontSize: 'small' }}>中国-贵州贵阳</span>
          </div>
          <div className="flex items-center justify-between">
            <div className="flex justify-end items-center footer_icons">
              <a href="https://discord.gg/U6PgegJNVa" target="_blank" rel="noopener noreferrer"><i className="bx bxl-discord-alt" style={{ color: 'var(--text-color)', opacity: '.7', fontSize: 'xx-large' }}></i></a>
              <a href="https://github.com/bitrecx" target="_blank" rel="noopener noreferrer"><i className="bx bxl-github" style={{ color: 'var(--text-color)', opacity: '.7', fontSize: 'xx-large' }}></i></a>
              <a href="https://v.douyin.com/Dyb8b6G/" target="_blank" rel="noopener noreferrer"><i className="bx bxl-tiktok" style={{ color: 'var(--text-color)', opacity: '.7', fontSize: 'xx-large' }} ></i></a>
            </div>
          </div>
        </div>
      </div>
    );
  };
  
  export default Footer;
  