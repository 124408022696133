import React, { useState, useEffect } from 'react';
import './App.css';
import Header from '../views/header';
import Footer from '../views/footer';
import Home from './home';
import Doc from './docs';
import Cooperate from './cooperate';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

const App: React.FC = () => {
  const [darkMode, setDarkMode] = useState<boolean>(true);

  const toggleDarkMode = () => {
    setDarkMode(!darkMode);
  };

  return (
    <Router>
      <div id='app' className={darkMode ? 'dark' : 'light'} style={{ minHeight: 'calc(100vh)', position: 'relative',display: 'flex',flexDirection: 'column'}}>
          <Header toggleDarkMode={toggleDarkMode}></Header>
          <div style={{ flex: '1' }}>
          <Routes>
            <Route path="/" Component={Home} />
            <Route path="/home" Component={Home} />
            <Route path="/docs" Component={Doc} />
            <Route path="/cooperate" Component={Cooperate} />
          </Routes></div>
          <Footer ></Footer>
      </div>
    </Router>
  );
}

export default App;
