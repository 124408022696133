import React from 'react';
import ReactDOM from 'react-dom/client';
import './font.css'
import './index.scss';
import App from './pages/App';
import reportWebVitals from './reportWebVitals';

const font0 = new FontFace('BreakBold', `url(${process.env.PUBLIC_URL}/assets/fonts/BreakBold.ttf)`);
const font1 = new FontFace('CodeNewRomanNF', `url(${process.env.PUBLIC_URL}/assets/fonts/CodeNewRomanNerdFont-Regular.otf)`);

Promise.all([font0.load(), font1.load()])
  .then(() => {
    // 字体加载完成后渲染应用
    const root = ReactDOM.createRoot(
      document.getElementById('root') as HTMLElement
    );
    root.render(
      <React.StrictMode>
        <App />
      </React.StrictMode>
    );
    
    // 添加 @font-face 规则
    document.fonts.add(font0);
    document.fonts.add(font1);
  })
  .catch((error) => console.error('Font loading failed:', error));

reportWebVitals();
