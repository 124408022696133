

import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';

import InfiniteView from '../../views/infiniteview';
import FooterNav from '../../views/footer/nav';
function Home() {
    const [active, setActive] = useState('design');
    const [tailarr] = useState(["ode", "onstruct", "ombine", "onstitution", "ompile", "ompress", "onvert"]);
    const location = useLocation();

    useEffect(() => {

    }, [location.pathname]);


    return (
        <>
            <InfiniteView ></InfiniteView>
            <FooterNav></FooterNav>
        </>
    );
}

export default Home;
