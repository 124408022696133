

import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import './index.scss'

interface Props {
    toggleDarkMode: () => void;
}
const Header: React.FC<Props> = ({ toggleDarkMode }) => {
    const [active, setActive] = useState('design');
    const [tailarr] = useState(["ode", "onstruct", "ombine", "onstitution", "ompile", "ompress", "onvert"]);
    const location = useLocation();


    useEffect(() => {
        if (location.pathname === "/") {
            setActive('design');
        } else if (location.pathname.includes("/docs")) {
            setActive('docs');
        } else if (location.pathname.includes("/cooperate")) {
            setActive('cooperate');
        }
    }, [location.pathname]);

    return (
        <div className="flex justify-between navbar">
            <div className="flex justify-start items-center">
                <img src="/img/logo_ico.png" alt=""
                    style={{ height: '40px', padding: '0 3px', transform: 'translateY(-60px)', filter: 'drop-shadow(var(--logo-color) 0 60px)' }} />
                <span style={{ fontFamily: 'BreakBold', fontSize: '26px', marginTop: '8px' }}>bitrec</span>
                <div className="wrapper">
                    <div className="wrapper__box-wrapper">
                        <div className="wrapper__box-wrapper__line">
                            <div>
                                {tailarr.map(tail => (
                                    <span key={tail} style={{ fontFamily: 'BreakBold', fontSize: '26px', height: '31.5px' }}>{tail}</span>
                                ))}
                            </div>
                            <div>
                                {tailarr.map(tail => (
                                    <span key={tail} style={{ fontFamily: 'BreakBold', fontSize: '26px', height: '31.5px' }}>{tail}</span>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="flex justify-start items-center">
                <Link className={`v-navbar__item v-navbar__item_select ${active === 'design' ? 'v-active' : ''}`} to="/" onClick={() => setActive('design')}>
                    设计
                </Link>
                <Link className={`v-navbar__item v-navbar__item_select ${active === 'docs' ? 'v-active' : ''}`} to="/docs" onClick={() => setActive('docs')}>
                    文档
                </Link>
                <Link className={`v-navbar__item v-navbar__item_select ${active === 'cooperate' ? 'v-active' : ''}`} to="/cooperate" onClick={() => setActive('cooperate')}>
                    合作
                </Link>
                <b className="v-navbar__item">|</b>
                <label className="v-navbar__item switch ">
                    <input type="checkbox" onClick={toggleDarkMode} />
                    <span className="slider round flex justify-around items-center">
                        <i className="bx bxs-sun" style={{ color: '#d9ae00', fontSize: 'small' }}></i>
                        <i className="bx bxs-moon" style={{ color: '#d9ae00', fontSize: 'small' }}></i>
                    </span>
                </label>
            </div>
        </div>
    );
}

export default Header;
