// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* index.scss */
.doc-container {
  display: flex;
}

.doc-content {
  flex: 1 1;
  padding: 20px;
  position: relative;
}

.sidebar {
  width: 200px;
  background: var(--background-color);
  padding: 10px;
}

/* index.scss */
.toc {
  padding: 1rem;
  border-radius: 0.375rem;
}

.toc-header {
  padding-bottom: 0.25rem;
  margin-bottom: 0.25rem;
}

.toc-links {
  display: flex;
  flex-direction: column;
  padding-left: 0.25rem;
}

.toc-link {
  color: #718096; /* Tailwind's text-slate-500 */
}

.toc-links a {
  font-weight: lighter;
  font-size: small;
}

.toc-link._3 {
  padding-left: 0.75rem; /* Tailwind's pl-3 */
}

.toc-link._4 {
  padding-left: 1.5rem; /* Tailwind's pl-6 */
}

.toc-link._undefined {
  padding-left: 2rem; /* Custom padding for undefined depth */
}

/* PrevNext.scss */
.prev-next-cont {
  display: flex;
  gap: 1rem;
  justify-content: space-between;
  padding: 1rem;
  border-radius: 0.375rem;
  background: var(--logo-background-color);
}

.link-item a {
  display: flex;
  gap: 0.5rem;
}`, "",{"version":3,"sources":["webpack://./src/pages/docs/index.scss"],"names":[],"mappings":"AAAA,eAAA;AAEA;EACI,aAAA;AAAJ;;AAGA;EACI,SAAA;EACA,aAAA;EACA,kBAAA;AAAJ;;AAGA;EACI,YAAA;EACA,mCAAA;EACA,aAAA;AAAJ;;AAGA,eAAA;AAEA;EACI,aAAA;EACA,uBAAA;AADJ;;AAIA;EACI,uBAAA;EACA,sBAAA;AADJ;;AAIA;EACI,aAAA;EACA,sBAAA;EACA,qBAAA;AADJ;;AAIA;EACI,cAAA,EAAA,8BAAA;AADJ;;AAIA;EACI,oBAAA;EACA,gBAAA;AADJ;;AAIA;EACI,qBAAA,EAAA,oBAAA;AADJ;;AAIA;EACI,oBAAA,EAAA,oBAAA;AADJ;;AAIA;EACI,kBAAA,EAAA,uCAAA;AADJ;;AAKA,kBAAA;AAEA;EACI,aAAA;EACA,SAAA;EACA,8BAAA;EACA,aAAA;EACA,uBAAA;EACA,wCAAA;AAHJ;;AAMA;EACI,aAAA;EACA,WAAA;AAHJ","sourcesContent":["/* index.scss */\n\n.doc-container {\n    display: flex;\n}\n\n.doc-content {\n    flex: 1;\n    padding: 20px;\n    position: relative;\n}\n\n.sidebar {\n    width: 200px;\n    background: var(--background-color);\n    padding: 10px;\n}\n\n/* index.scss */\n\n.toc {\n    padding: 1rem;\n    border-radius: 0.375rem;\n}\n\n.toc-header {\n    padding-bottom: 0.25rem;\n    margin-bottom: 0.25rem;\n}\n\n.toc-links {\n    display: flex;\n    flex-direction: column;\n    padding-left: 0.25rem;\n}\n\n.toc-link {\n    color: #718096; /* Tailwind's text-slate-500 */\n}\n\n.toc-links a {\n    font-weight: lighter;\n    font-size: small;\n}\n\n.toc-link._3 {\n    padding-left: 0.75rem; /* Tailwind's pl-3 */\n}\n\n.toc-link._4 {\n    padding-left: 1.5rem; /* Tailwind's pl-6 */\n}\n\n.toc-link._undefined {\n    padding-left: 2rem; /* Custom padding for undefined depth */\n}\n\n\n/* PrevNext.scss */\n\n.prev-next-cont {\n    display: flex;\n    gap: 1rem;\n    justify-content: space-between;\n    padding: 1rem;\n    border-radius: 0.375rem;\n    background: var(--logo-background-color);\n}\n\n.link-item a {\n    display: flex;\n    gap: 0.5rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
