import React, { useEffect, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import rehypeSlug from 'rehype-slug';
import remarkGfm from 'remark-gfm';

interface MarkdownLoaderProps {
    file: string;
    onTocChange: (links: TocLink[]) => void;
}

interface TocLink {
    id: string;
    text: string;
    depth: number;
    children?: TocLink[];
}

const extractTocLinks = (content: string): TocLink[] => {
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = content;
    const headers = Array.from(tempDiv.querySelectorAll('h1, h2, h3, h4, h5, h6'));

    return headers.map(header => ({
        id: header.id,
        text: header.textContent || '',
        depth: parseInt(header.tagName[1], 10)
    }));
};

const MarkdownLoader: React.FC<MarkdownLoaderProps> = ({ file, onTocChange }) => {
    const [content, setContent] = useState<string>('');

    useEffect(() => {
        fetch(`${process.env.PUBLIC_URL}/docs/${file}`)
            .then(response => response.text())
            .then(text => {
                setContent(text);
                const tocLinks = extractTocLinks(text);
                onTocChange(tocLinks);
            });
    }, [file, onTocChange]);

    return (
        <ReactMarkdown rehypePlugins={[rehypeSlug]} remarkPlugins={[remarkGfm]}>
            {content}
        </ReactMarkdown>
    );
};

export default MarkdownLoader;