import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

interface NavItem {
    title: string;
    path: string;
    file: string;
}

interface SidebarProps {
    onSelect: (file: string) => void;
}

const Sidebar: React.FC<SidebarProps> = ({ onSelect }) => {
    const [navItems, setNavItems] = useState<NavItem[]>([]);

    useEffect(() => {
        fetch(`${process.env.PUBLIC_URL}/docs/nav.json`)
            .then(response => response.json())
            .then(data => setNavItems(data))
            .catch(error => console.error('Error loading nav.json:', error));
    }, []);

    return (
        <div className="sidebar">
            <ul>
                {navItems.map((item, index) => (
                    <li key={index}>
                        <Link to={item.path} onClick={() => onSelect(item.file)}>
                            {item.title}
                        </Link>
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default Sidebar;
